<template>
	<div class="mainTem">
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_b6a8725d78c586be')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<!-- <div class="filterBarList">
				<el-menu :default-active="filterData.labelStatus" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.labelStatusList" :key="item.value">
						<span>{{i18nFormatter(item.value)}}</span>
					</el-menu-item>
				</el-menu>
			</div> -->
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="warning" size="small" icon="el-icon-delete" @click="CancelOrderAction($event)">
							{{ $t('i18nn_bcef26d98d690aba') }}</el-button>
					</li>
					<li>
						<el-button type="danger" size="small" icon="el-icon-close"
							@click="CancelPrintingAction($event)">{{ $t('i18nn_4ca88dd2e7071844') }}</el-button>
					</li>
					<li>
						<el-button @click="batchDownloadAction($event, null)" type="primary" size="small"
							icon="el-icon-download">{{$t('i18nn_21b22a8018f3cba2')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li>
					<!-- <li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.daterange" size="medium" type="daterange" align="right"
							:clearable="false" unlink-panels range-separator="-" start-placeholder="start date"
							end-placeholder="end date" :picker-options="pickerOptions" format="yyyy-MM-dd"
							value-format="yyyy-MM-dd" @change="initData()"></el-date-picker>
					</li> -->
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()">
						</el-button>
					</li> -->
				</ul>
			</div>
		</div>
		<!--更多筛选-->
		<!-- <el-drawer :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter" :direction="'rtl'">
			<div class="drawerFilterCon">
				<ul class="drawerFilterList">
					<li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li>
					<li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li>
					<li>
						<span class="filterName">{{ $t('i18nn_5b7f75f3443354a2') }}</span>
						<el-date-picker v-model="filterData.daterange" size="medium" type="daterange" align="right"
							:clearable="false" unlink-panels range-separator="-" start-placeholder="start date"
							end-placeholder="end date" :picker-options="pickerOptions" format="yyyy-MM-dd"
							value-format="yyyy-MM-dd" @change="initData()"></el-date-picker>
					</li>
					<li>
						<span class="filterName">{{ $t('i18nn_06dd7cb65641390b') }}</span>
						<el-select filterable clearable v-model="filterData.labelReady"
							:placeholder="$t('i18nn_2ad108ab2c560530')" size="medium" style="width: 100px;"
							@change="initData()">
							<el-option v-for="item in selectOption.labelReadyList" :key="item.value" :label="i18nFormatter2(item.value)"
								:value="item.value"></el-option>
						</el-select>
					</li>
					<li>
						<span class="filterName">{{ $t('i18nn_abf9f4b8c93d641e') }}</span>
						<el-input
							type="text"
							v-model="filterData.orderNumber"
							size="medium"
							clearable
							@keyup.enter.native="initData()"
							maxlength="50"
							:placeholder="$t('i18nn_5a9aefbc03c778f7')"
							style="width: 180px;"
						/>
					</li>
					<li class="filterBtnCon">
						<el-button icon="el-icon-search" size="medium" type="primary" @click="queryMoreFilter()">{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
					</li>
				</ul>
			</div>
		</el-drawer> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @row-click="handleCurrentChange"
				@selection-change="handleSelectionChange" v-loading="loading">
				<!-- <el-table-column type="selection" fixed="left" align="center" width="55"></el-table-column> -->
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<!-- <el-table-column prop="exprStatusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						
						<div class="tag-color-grey" v-if="'0' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
						<div class="tag-color-blue" v-else-if="'10' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
						<div class="tag-color-cyan" v-else-if="'20' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
						<div class="tag-color-green" v-else-if="'30' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
						<div class="tag-color-yellow" v-else-if="'35' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
						<div class="tag-color-orange" v-else-if="'40' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
						<div class="tag-color-red" v-else-if="'50' == scope.row.exprStatus">
							<div>{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}</div>
						</div>
						<div class="tag-color-brown" v-else-if="'60' == scope.row.exprStatus">
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
						<div class="tag-color-purple" v-else>
							{{ $Utils.i18nKeyText(scope.row,'exprStatusName') }}
						</div>
					</template>
				</el-table-column> -->
				
				<!-- <el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column> -->
				<el-table-column prop="batchNo" :label="$t('i18nn_b458f03c43a90ee9')" width="100">
					<template slot-scope="scope">
						<div>
							<!-- <el-link v-if="scope.row.batchNo" type="danger" size="mini"
								@click="toActionView($event, scope.row, scope.$index)"> -->
								{{ scope.row.batchNo }}
							<!-- </el-link> -->
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_43a3586339251494')"></el-table-column> -->
				<!-- <el-table-column prop="sysDefOrderNum" :label="$t('i18nn_7f6c721b57ed0e7b')" min-width="100">
					<template slot-scope="scope">
						<div>
								{{ scope.row.sysDefOrderNum }}
						</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column> -->
				<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')" min-width="150">
					<template slot-scope="scope">
						<div>
							<el-link type="primary" size="mini" icon=""
								@click="toDetActionView($event, scope.row, scope.$index)">
								{{ scope.row.orderNumber }}
							</el-link>
						</div>
					</template>
				</el-table-column>
				
				<!-- <el-table-column prop="thirdOrderNumber" :label="$t('i18nn_83b1b50eb00a9fb6')"></el-table-column> -->
				
				
				<!-- <el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column> -->
				
				<!-- <el-table-column prop="tracking" :label="$t('i18nn_581bc71c027386cb')"></el-table-column> -->
				
				<el-table-column prop="carrier" :label="$t('i18nn_e63fc078a7baf7c9')" min-width="180">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_b0c50b6ff9674173')}}</span>：
									<span>{{scope.row.carrier}}</span>
								</li>
								<li>
									<span>{{$t('i18nn_581bc71c027386cb')}}</span>：
									<span>
										<!-- <el-link v-if="scope.row.tracking" type="primary" size="mini" icon="" @click="toExpLgsTrackView($event, scope.row, scope.$index)"> -->
											{{ scope.row.tracking }}
										<!-- </el-link> -->
									</span>
								</li>
								<li>
									<span>{{$t('i18nn_e83443dd6c718cf9')}}</span>：
									<span style="color:#E6A23C;">{{scope.row.derivedStatus}}</span>
								</li>
								<li>
									<span>{{$t('i18nn_41d020489ecacbf0')}}</span>：
									<span style="color:#333333;">{{scope.row.trackDate}}</span>
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="dayCount" :label="$t('i18nn_8f89632c68d3b9a2')">
					<template slot-scope="scope">
						<div>
							<el-tag type="danger" v-if="scope.row.dayCount>=3">{{scope.row.dayCount}}</el-tag>
							<el-tag type="info" v-else>{{scope.row.dayCount}}</el-tag>
						</div>
					</template>
				</el-table-column>
				
				<!-- <el-table-column prop="isSelfLabel" :label="$t('i18nn_3601b5a98803f4d7')">
					<template slot-scope="scope">
						<div v-if="scope.row.isSelfLabel">
							<el-tag type="warning" v-if="'10' == scope.row.isSelfLabel">{{$t('i18nn_198d8fd115bfc3d9')}}</el-tag>
							<el-tag type="success" v-else-if="'20' == scope.row.isSelfLabel">{{$t('i18nn_15bc1e82df3428d8')}}</el-tag>
							<el-tag type="info" v-else>{{ scope.row.isSelfLabel }}</el-tag>
						</div>
					</template>
				</el-table-column> -->
				
				<!-- <el-table-column prop="labelStatusPressName" :label="$t('i18nn_90429ac39f4f4634')"></el-table-column> -->
				
				<!-- <el-table-column prop="labelUrl" :label="$t('i18nn_1324a1d91ae3de53')">
					<template slot-scope="scope">
						<div class="over_ellipsis" style="max-width: 100%;">
							<a v-if="scope.row.labelInfoDto" :href="scope.row.labelInfoDto.labelUrl"
								:title="scope.row.labelInfoDto.labelUrl" target="_blank">
								{{ scope.row.labelInfoDto.labelUrl }}
							</a>
						</div>
						<div class="over_ellipsis" style="max-width: 100%;">
							<a v-if="scope.row.labelUrl" :href="scope.row.labelUrl" :title="scope.row.labelUrl"
								target="_blank">{{ scope.row.labelUrl }}</a>
						</div>
					</template>
				</el-table-column> -->
				
				<!-- <el-table-column prop="rate" :label="$t('i18nn_d7315fb8114eb446')"></el-table-column> -->
				
				<!-- <el-table-column prop="goodsSku" label="SKU">
					<template slot-scope="scope2">
						<div v-if="scope2.row.expressSkuList">
								<div v-for="(item,index) in scope2.row.expressSkuList" :key="index">
									{{item.goodsSku}}
								</div>
						</div>
					</template>
				</el-table-column> -->
				<el-table-column prop="serchSku" label="SKU"></el-table-column>
				
				<!-- <el-table-column prop="packingNo" :label="$t('i18nn_b7a10fe4e9528362')"></el-table-column> -->
				<el-table-column prop="senderAddr" :label="$t('i18nn_f6283c46ba925ab4')"></el-table-column>
				<!-- <el-table-column prop="returnAddr" :label="$t('Storage.DropShipping.place_receipt')"></el-table-column> -->
				<!-- 
				<el-table-column prop="name" :label="$t('Storage.DropShipping.Addressee_name')"></el-table-column>
				
				<el-table-column prop="company" :label="$t('Storage.DropShipping.company')"></el-table-column>
				
				<el-table-column prop="phone" :label="$t('Storage.DropShipping.contact_way')"></el-table-column>
				
				<el-table-column prop="email" label="E-Mail"></el-table-column>
				<el-table-column prop="street1" :label="$t('Storage.DropShipping.Address_1')"></el-table-column>
				<el-table-column prop="street2" :label="$t('Storage.DropShipping.Address_2')"></el-table-column>
				<el-table-column prop="city" :label="$t('Storage.DropShipping.city')"></el-table-column>
				<el-table-column prop="state" :label="$t('Storage.DropShipping.State')"></el-table-column>
				<el-table-column prop="postalCode" :label="$t('Storage.DropShipping.postal_code')"></el-table-column>
				<el-table-column prop="country" :label="$t('Storage.DropShipping.country')"></el-table-column> -->
				<!-- <el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')" class="">
					<template slot-scope="scope">
							<div v-if="scope.row.attachments">
								<el-link type="primary">
									...
									<span>{{ $t('i18nn_73bbf51487ec69e9') }}</span>
									<span>{{ scope.row.attachments.length }}</span>
									<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
								</el-link>
							</div>
					</template>
				</el-table-column> -->
				
				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')" >
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="exprStatusTime" :label="$t('i18nn_d99d790ec4383bfb')"></el-table-column> -->
				
				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column> -->
				
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_0ae081c3b8c4d4a1')}}</span>:{{scope.row.commitDate}}
								</li>
								<li>
									<span>{{$t('i18nn_d99d790ec4383bfb')}}</span>:{{scope.row.exprStatusTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->

				<!-- <el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column> -->

				<!-- <el-table-column prop="carrierServiceLevel" :label="$t('i18nn_61fc9e07fff97930')"></el-table-column> -->

				<!-- <el-table-column prop="tranckingNo" :label="$t('i18nn_4531476fa35570f0')" width="150px">
				</el-table-column> -->

				<!-- <el-table-column prop="shippingPrice" :label="$t('i18nn_ab6994d16b9b4366')"></el-table-column> -->
				<!-- <el-table-column prop="shippintDesc" :label="$t('i18nn_673767ab6ddb37cd')"></el-table-column> -->

				<!-- <el-table-column prop="serviceFee" :label="$t('i18nn_d353bf2887ca0603')"></el-table-column> -->

				<!-- <el-table-column prop="serviceFee" :label="$t('i18nn_3c4a40675f59cd1c')">
					<template slot-scope="scope">
						<div>
							{{ (parseFloat(scope.row.shippingPrice?scope.row.shippingPrice:0) + parseFloat(scope.row.serviceFee?scope.row.serviceFee:0)+ parseFloat(scope.row.sellerFee?scope.row.sellerFee:0)).toFixed(2) }}
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="labelOrderNum" :label="$t('i18nn_48ebfc4319cbac59')" width="150px">
				</el-table-column>

				<el-table-column prop="labelTime" :label="$t('i18nn_5f6b842a6ff748ab')"></el-table-column>
				<el-table-column prop="createTime" :label="$t('i18nn_76e82afd9c2b6ca1')"></el-table-column> -->

				<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>
							<div style="margin-bottom: 10px;" v-if="'20' !== scope.row.labelStatus">
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
							</div>
						</div>
					</template>
				</el-table-column> -->
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
		<!-- 发货轨迹查询 -->
		<whQueryExpLgsTrack :openTime="openTimeExpLgsTrack" :row="ExpLgsTrackRow"></whQueryExpLgsTrack>
		
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhExpressTrunckNotOnlinePage" :expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	import {
		ExpressSheetDetList_status,
		ExpressSheetDetList_status2
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';
	
	import {
		fileZipAndDownload
	} from '@/utils/fileZipDownload.js';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import whQueryExpLgsTrack from '@/components/StorageCenter/components/whQueryExpLgsTrack.vue';
	export default {
		components: {
			SelAgentUser,
			whExcelCustom,
			whNoSelect,
			whQueryExpLgsTrack
		},
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		// props: {
		// 	// mobile:"",
		// 	// isSel:{
		// 	//   default: function() {
		// 	//     return false
		// 	//   },
		// 	//   type: Boolean
		// 	// },
		// 	exprId: {
		// 		default: function() {
		// 			return '';
		// 		},
		// 		type: String
		// 	}
		// },
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				drawerFilter:false,
				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',
				openTimeExpLgsTrack:'',
				ExpLgsTrackRow:{},

				pickerOptions: this.$PickerDate.pickerOptions_2(),

				loading: false,

				loading_load: false,
				tableData: [],

				multipleSelection: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
					// wh_size_unit: [],
					// // wh_packing_type:[],
					// wh_car_tms_type: [],
					// wh_no: [],
					labelReadyList: ExpressSheetDetList_status2,
					// labelStatusList: ExpressSheetDetList_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// agentUser:'',
					// whNo:'',

					daterange: [],
					// exprStatus: '',
					whNo: '',
					orderNumber: '',
					// cusNo: '',
					commitDate: '',
					keyword: '',
					labelReady: '',
					labelStatus: '',
					agentUser: ''
					// "accountPeriod":"",
					// packId: "",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: '',
				}
			};
		},
		// watch: {
		// 	exprId: function(newVal, oldVal) {
		// 		console.log('watchKey');
		// 		// if (newVal) {
		// 		// console.log('watch openDateTime HyUpLoadImg.vue');
		// 		this.initData();
		// 		// }
		// 	}
		// },
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				// if (this.exprId) {
				this.tableData = [];
				this.getPageData();
				// }
				// this.getDicData();
			},
			//打开更多筛选
			// openMoreFilter() {
			// 	this.drawerFilter = true;
			// },
			// //查询更多筛选
			// queryMoreFilter() {
			// 	this.initData();
			// 	this.drawerFilter = false;
			// },
			// i18nFormatter(value) {
			// 	return i18nStatus(value, this.selectOption.labelStatusList)
			// },
			i18nFormatter2(value) {
				return i18nStatus(value, this.selectOption.labelReadyList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			exportExcelAction() {
				// 工单号 日期 单号 快递公司 快递单号 面单地址  快递费用 收件人名  公司 联系方式 e-mail 地址1 地址2 城市 州/省 国家
			
				let columns = [
					// {
					// 	title: this.$t('i18nn_6235565b185f0725'),
					// 	key: 'workNo'
					// },
					// {
					// 	title: "SKU CODE",
					// 	key: 'goodsSkuImg',
					// 	types: 'image'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.date'),
					// 	key: 'commitDate'
					// },
					{
						title: this.$t('i18nn_c944a6686d996ab3'),
						key: 'senderAddr'
					},
					{
						title: this.$t('i18nn_b458f03c43a90ee9'),
						key: 'batchNo'
					},
					
					
					{
						title: this.$t('Storage.skuInfo.number'),
						key: 'orderNumber'
					},
					{
						title: this.$t('i18nn_b0c50b6ff9674173'),
						key: 'carrier'
					},
					{
						title: this.$t('i18nn_581bc71c027386cb'),
						key: 'tracking'
					},
					{
						title: this.$t('i18nn_8f89632c68d3b9a2'),
						key: 'dayCount'
					},
					// {
					// 	title: this.$t('i18nn_1324a1d91ae3de53'),
					// 	key: 'labelUrl',
					// 	types: 'custom',
					// 	formatter: function(row) {
					// 		// let labelUrl_1 = '';
					// 		// let labelUrl_2 = '';
					// 		let labelUrlArr = [];
					// 		if (row.labelInfoDto && row.labelInfoDto.labelUrl) {
					// 			labelUrlArr.push(row.labelInfoDto.labelUrl);
					// 		}
					// 		if (row.labelUrl) {
					// 			labelUrlArr.push(row.labelUrl);
					// 		}
					// 		return labelUrlArr.join(',');
					// 	}
					// },
					// {
					// 	title: this.$t('i18nn_d7315fb8114eb446'),
					// 	key: 'rate'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.Addressee_name'),
					// 	key: 'name'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.company'),
					// 	key: 'company'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.contact_way'),
					// 	key: 'phone'
					// },
					// {
					// 	title: 'E-Mail',
					// 	key: 'email'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.Address_1'),
					// 	key: 'street1'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.Address_2'),
					// 	key: 'street2'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.city'),
					// 	key: 'city'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.State'),
					// 	key: 'state'
					// },
					// {
					// 	title: this.$t('Storage.DropShipping.country'),
					// 	key: 'country'
					// }
			
					// {
					// 	title: this.$t('hytxs0000037'),
					// 	key: 'totalStock',
					// 	types: 'custom',
					// 	formatter:function(row){
					// 		if(row.placeStockTotal && row.placeStockTotal.totalStock){
					// 			return row.placeStockTotal.totalStock
					// 		} else {
					// 			return 0;
					// 		}
			
					// 	}
					// },
					{
						title: 'SKU',
						key: 'serchSku',
						// types: 'custom',
						// formatter: function(row) {
						// 	// let labelUrl_1 = '';
						// 	// let labelUrl_2 = '';
						// 	// let skuList = [];
						// 	if (row.expressSkuList) {
						// 		return row.expressSkuList
						// 			.map(item => {
						// 				// skuList.push(item.goodsSku);
						// 				return item.goodsSku;
						// 			})
						// 			.join(',');
						// 	} else {
						// 		return '';
						// 	}
								
						// 	// return skuList.join(",");
						// }
					},
					{
						title: this.$t('i18nn_0ae081c3b8c4d4a1'),
						key: 'commitDate'
					},
				];
				// let Data = this.tableDataCatch;
				// return columns;
				let Data = this.tableData;
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'ExpressTrunckNotOnlineList';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();
				// let data_dom = JQ('#ex_table').find(".el-table__body");
				// let obj_key_img = {};
				// // console.log(data_dom.find("tr"));
				// data_dom.find("tr").each((index, domEle)=>{
				// 	// console.log("index",index);
				// 	let imgObj = JQ(domEle).find("td .cell img");
				// 	// console.log("imgObj",imgObj);
				// 	if(imgObj){
				// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
				// 	}
				// })
				// // console.log("obj_key_img",obj_key_img);
				// Data.forEach(item=>{
				// 	item.goodsSkuImg = obj_key_img[item.goodsSku];
				// })
				// console.log('Data',Data);
				// return;
				// excelUtilsNew.exportExcel(columns, Data, 'wh_dropshipping',{height:20});
			},
			
			toDetActionView(event, row){
				event.stopPropagation();
				this.$router.push({name:"WarehouseDropShipping",query:{orderNumber:row.orderNumber}})
			},
			
			//发货单轨迹查询
			// toExpLgsTrackView(event,row) {
			// 	event.stopPropagation();
			// 	// this.$router.push({ name: 'WhDropShippingDet', query: { orderNumber: row.orderNumber } });
			// 	if(row.carrier && row.tranckingNo){
			// 		this.openTimeExpLgsTrack = new Date().getTime();
			// 		this.ExpLgsTrackRow = {
			// 			carrier:row.carrier,
			// 			tracking:row.tranckingNo,
			// 			id: row.exprId
			// 		};
			// 	} else {
			// 		this.$message.warning(this.$t('i18nn_c136c2ffbc49c6e7'));
			// 	}
			// },
		
			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			// this.$message({
			// 			//   type: 'success',
			// 			//   message: '删除成功!'
			// 			// });
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {
			// 			// this.$message({
			// 			//   type: 'info',
			// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
			// 			// });
			// 		});
			// },
			// //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);

			// 	this.postData(
			// 		this.$urlConfig.WhThridLabelInfoDetDel + '/' + row.id, {},
			// 		() => {
			// 			this.initData();
			// 			this.$message.success('删除成功！');
			// 		},
			// 		'delete'
			// 	);
			// },


			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//查询数据
			// serPageData() {
			// 	// this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.labelStatus = key;
				this.initData();
			},
			//分页的筛选项数据
			pageFilterData() {
				let startTime = '';
				let endTime = '';
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					// this.$message.warning(this.$t('tips.Please_Sel_date'));
				}

				return {
					exprId: this.exprId, //"orde的ID",
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
					orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
					// userId: this.filterData.cusNo ? this.filterData.cusNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					labelReady: this.filterData.labelReady ? this.filterData.labelReady : null,
					labelStatus: this.filterData.labelStatus ? this.filterData.labelStatus : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,

					createTimeStart: startTime ? startTime : null,
					createTimeEnd: endTime ? endTime : null
				};
			},

			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhExpressTrunckNotOnlinePage, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//撤销打单
			CancelPrintingAction(event) {
				event.stopPropagation();
				// this.getPriterOrderRate(row,index,row.id);
				let parm = [];
				// 	if (row) {
				// 		parm = [row.id];
				// 	} else {
				// 		//多条
				let dataList = this.multipleSelection;
				// let dataList = this.tableData;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('tips.Please_Sel'));
					return;
				}
				// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
				// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
				// 		//   return;
				// 		// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// 	}
				this.$confirm('确定 撤销打单 吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhThridLabelCancelPrinting, parm, () => {
							this.initData();
							this.$message.success(this.$t('i18nn_778d871227234f32'));
							// this.$router.push({name:'WhPackage',query:{open:'add'}});
						});
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},
			//取消面单
			CancelOrderAction(event) {
				event.stopPropagation();
				// this.getPriterOrderRate(row,index,row.id);
				let parm = [];
				// 	if (row) {
				// 		parm = [row.id];
				// 	} else {
				// 		//多条
				let dataList = this.multipleSelection;
				// let dataList = this.tableData;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('tips.Please_Sel'));
					return;
				}
				// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
				// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
				// 		//   return;
				// 		// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// 	}
				this.$confirm('确定 取消面单 吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhThridLabelCancelOrder, parm, () => {
							this.initData();
							this.$message.success(this.$t('i18nn_854a071f3cbf2761'));
							// this.$router.push({name:'WhPackage',query:{open:'add'}});
						});
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},

			//批量下载附件
			batchDownloadAction(event, row) {
				event.stopPropagation();

				let dataList = this.multipleSelection;
				// let dataList = this.tableData;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('tips.Please_Sel'));
					return;
				}

				if (!!window.ActiveXObject || 'ActiveXObject' in window) {
					this.$message.warning(this.$t('i18nn_2de6c4eb2e40cf1f'));
				}

				let files = [];
				// console.log('dataList', dataList);
				dataList.forEach(item => {
					if (item.labelUrl) {
						files.push(item.labelUrl);
					}
				});
				// files.push('https://storage-prod.vitedirect.com/labels/2022/4/272752978128.pdf');
				// console.log('files', files);
				if (files.length < 1) {
					this.$message.warning(this.$t('i18nn_c26d5c523b0b69d3'));
					return;
				}
				this.$message.success('当前页，共 ' + files.length + ' 个面单，正在压缩');

				fileZipAndDownload(files, 'ExpressSheetDetListLabel');
			},
			
			//提交数据
			postData(url, formData, callback, type) {
				// let _this = this;
				this.loading_load = true;
				let http = {};
				if ('delete' == type) {
					http = this.$http.delete(url, formData);
				} else {
					http = this.$http.put(url, formData);
				}
				http
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.submitError');
							}
							this.$alert(data.msg, this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading_load = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				// this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/deep/ .el-input-number,
	.el-select {
	
	// width: 100px;
		.el-input__inner {
			text-align: left;
		}
	}

	.form_msg {
		color: #e6a23c;
	}
</style>
